import gql from "graphql-tag";
import useQuery from "hooks/useQuery";
import { GetCurrentUser } from "__generated__/GetCurrentUser";
import { COMPANY_LIST_FRAGMENT_GQL } from "gql/companyListQuery.gql";

const GENERIC_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      id
      email
      firstName
      lastName
      phone
      companies {
        id
        role
        ...CompanyListFragment
      }
    }
  }
  ${COMPANY_LIST_FRAGMENT_GQL}
`;

/**
 * Hook to fetch the current logged in user.
 * Use this hook instead of adding the currentUser resolver to your other queries!
 * If there is a field you need that is missing, just add it in here, for a single source of truth for the user.
 */
export default function useCurrentUser() {
  // Unless user logs out, or it's the first page load, this shall always be cached.
  const { loading, data } = useQuery<GetCurrentUser, void>(GENERIC_CURRENT_USER_QUERY, {
    fetchPolicy: "cache-first",
  });

  return {
    loading,
    data,
    currentUser: data?.currentUser,
  };
}
