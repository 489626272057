import React from "react";
import Login from "pages/auth/login";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/react";
import { useCompanyIDAndProjectID } from "helpers/utils";
import usePendo from "hooks/usePendo";
import useCurrentUser from "hooks/data/useCurrentUser";

export interface GatekeeperContextState {
  loading: boolean;
}

const defaultState: GatekeeperContextState = {
  loading: false,
};
export const GatekeeperContext = React.createContext<GatekeeperContextState>(defaultState);

interface Props {
  children: React.ReactNode;
}

/**
 * Gatekeeper (noun): a person or thing that controls access to something.
 *
 * Gatekeeper verifies that our users are authenticated before allowing them access to parts of our application.
 */
export default function Gatekeeper(props: Props) {
  const router = useRouter();
  const { companyID, projectID } = useCompanyIDAndProjectID();
  usePendo();

  const isSSR = typeof window === "undefined";
  if (router.route === "/_error") {
    // 404
    return <>{props.children}</>;
  }
  const pageRequiresAuth = !router.pathname.startsWith("/auth/");
  // if our user is authenticated, we will get data back (from either the backend or our local cache).
  // if we do not get data, we are not logged in.
  const { loading, data } = useCurrentUser();

  if (pageRequiresAuth && (isSSR || loading)) {
    // loading/SSR: return the unhydrated render (skeleton) of the page the user requested
    return (
      <GatekeeperContext.Provider
        value={{
          loading: true,
        }}
      >
        {props.children}
      </GatekeeperContext.Provider>
    );
  } else if (pageRequiresAuth && !data) {
    // user is not authenticated; show Login component
    // TODO: Potential circular reference - Should components import from pages?
    // log user out of Sentry/Mixpanel
    Sentry.setUser(null);
    return <Login />;
  } else {
    const currentUser = data?.currentUser;
    if (currentUser) {
      // identify to Sentry/Mixpanel
      const { email, id } = currentUser;
      Sentry.setUser({ email, id });

      // add custom sentry tags
      Sentry.setTag("companyID", companyID);
      Sentry.setTag("projectID", projectID);
    }
    return (
      <GatekeeperContext.Provider
        value={{
          loading: false,
        }}
      >
        {props.children}
      </GatekeeperContext.Provider>
    );
  }
}
