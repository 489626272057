import { useEffect } from "react";
import Cookies from "js-cookie";
import { gql, useApolloClient } from "@apollo/client";
import { useCompanyIDAndProjectID } from "helpers/utils";
import useCurrentUser from "hooks/data/useCurrentUser";

function initializePendo() {
  pendo &&
    pendo.getVisitorId &&
    pendo.initialize({
      // disable Pendo's in-app messaging if a cookie with the key PENDO_DISABLE_GUIDES exists.
      // we currently set this cookie in cypress/support/index.js as Pendo's overlay can cover elements we are testing.
      // https://support.pendo.io/hc/en-us/articles/360031862272-Installation-for-Single-Page-Frameworks
      disableGuides: Boolean(Cookies.get("PENDO_DISABLE_GUIDES")),
      visitor: {
        // For Intercom to initiate w/o an auth'd DADO user
        anonymous_id: pendo.getVisitorId(),
      },
    });
}

/**
 * Initializes Pendo once the window's "load" event is triggered.
 * This makes pendo less likely to fail since its library *should* be loaded
 * before initialize() and getVisitorId() are called.
 *
 * This must be wrapped within an ApolloProvider in order to access the
 * client/cache.
 */
const usePendo = () => {
  const { cache } = useApolloClient();
  const { companyID } = useCompanyIDAndProjectID();

  const { data } = useCurrentUser();

  useEffect(() => {
    window.addEventListener("load", initializePendo);

    return () => window.removeEventListener("load", initializePendo);
  }, []);

  const currentUser = data?.currentUser;

  if (currentUser) {
    // Read the current company information from the Cache.
    // Null if it doesn't exist. Or if companyID is undefined.
    const cachedCompany = cache.readFragment({
      id: `Company:${companyID}`,
      fragment: gql`
        fragment CompanyIdAndName on Company {
          id
          name
        }
      `,
    }) as Optional<{ id: string; name: string }>;

    let fullName = "";
    const { firstName, lastName } = currentUser;
    if (firstName && lastName) {
      fullName = `${firstName} ${lastName}`;
    }

    pendo.updateOptions({
      visitor: {
        id: currentUser.id,
        email: currentUser.email,
        full_name: fullName,

        // Metadata to be passed thru to Intercom.
        // May be redundant, but these specific fields are needed for
        // Intercom's user profile.
        user_id: currentUser.id,
        name: fullName,
      },
      account: {
        id: cachedCompany?.id || "BASE_APP",
        name: cachedCompany?.name || "BASE_APP",
      },
    });
  }

  function pendoTrack(name: string, data: Record<string, unknown>) {
    if (pendo && pendo.isReady && pendo.isReady()) {
      pendo.track(name, data);
    } else {
      setTimeout(() => {
        pendoTrack(name, data);
      }, 500);
    }
  }

  return { pendoTrack };
};

export default usePendo;
